import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import {
  IPurchaseStateCSV,
  IPurchaseStateWithCustomerRelationship,
} from "@smartrr/shared/entities/PurchaseState";
import {
  IPurchaseStateLineItemCSV,
  IPurchaseStateLineItemWithAsymmetricMetadata,
} from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";

import {
  mapPurchaseStateLineItemsWithDelay,
  mapPurchaseStatesWithDelay,
} from "@smartrr/vendor-portal/src/utils/mapSubscriptionWithDelay";
import {
  getCustomerPurchaseStateLineItems,
  getCustomerPurchaseStates,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";

import { getQueryObj } from "./getQueryObject";

export interface IExportCSVFilters {
  startDate?: string;
  endDate?: string;
  filterIn?: { [key: string]: string[] };
  filterLike?: { [key: string]: string };
  orderByField?: string;
}

export async function exportPurchaseStatesToCsvRows(
  activeOrg: IOrganization | null,
  currencyCode?: CurrencyCode,
  filters?: IExportCSVFilters
): Promise<IPurchaseStateCSV[]> {
  const startDate = filters?.startDate;
  const endDate = filters?.endDate;

  const orderByField = filters?.orderByField ?? "createdDate";
  const filterIn = filters?.filterIn;
  const filterLike = filters?.filterLike;

  const allData: IPurchaseStateWithCustomerRelationship[] = [];
  let pageNumber = 0;
  let totalPages = 1;

  while (pageNumber < totalPages) {
    const page = await getCustomerPurchaseStates(
      getQueryObj(pageNumber, startDate, endDate, filterIn, filterLike, orderByField)
    );

    if (!page) {
      throw new Error("Unknown error");
    }

    if (page.type === "error") {
      throw new Error(page.message);
    }

    allData.push(...page.body.data);
    pageNumber = page.body.pageNumber;
    totalPages = page.body.totalPages;
    pageNumber++;
  }

  return await mapPurchaseStatesWithDelay(allData, activeOrg, currencyCode, activeOrg?.billingTimezone);
}

export async function exportPurchaseStateLineItemsToCsvRows(
  activeOrg: IOrganization,
  currencyCode?: CurrencyCode,
  filters?: IExportCSVFilters
): Promise<IPurchaseStateLineItemCSV[]> {
  const startDate = filters?.startDate;
  const endDate = filters?.endDate;
  const orderByField = filters?.orderByField;
  const filterIn = filters?.filterIn;

  const allData: IPurchaseStateLineItemWithAsymmetricMetadata[] = [];
  let pageNumber = 0;
  let totalPages = 1;

  while (pageNumber < totalPages) {
    const page = await getCustomerPurchaseStateLineItems(
      getQueryObj(pageNumber, startDate, endDate, filterIn, undefined, orderByField)
    );
    if (!page) {
      throw new Error("Unknown error");
    }

    if (page.type === "error") {
      throw new Error(page.message);
    }

    allData.push(...page.body.data);
    pageNumber = page.body.pageNumber;
    totalPages = page.body.totalPages;
    pageNumber++;
  }

  return await mapPurchaseStateLineItemsWithDelay(allData, activeOrg, currencyCode);
}
