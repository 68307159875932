import { IOrganization, OrgUtils } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";

export function getSubscriptionTypeLabel<T extends IPurchaseStateWithCustomerRelationship>(
  organization: IOrganization,
  cps: T
): string {
  if (!cps.schedule) {
    return "Single Purchase";
  }

  if (cps.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active) {
    return cps.purchaseStateStatus;
  }

  const vendorSchedule = OrgUtils.getBillingSchedule(organization);
  const rruleManager = RRuleManager.fromJson(cps.schedule, vendorSchedule);
  const nextDelivery = rruleManager.nextActualDelivery;

  if (!nextDelivery) {
    return SubscriptionContractSubscriptionStatus.Expired;
  }

  const nextDeliveryDateString = nextDelivery.date.toLocaleString();

  return `Subscription${`, ${rruleManager.toText()}` || ` next delivery ${nextDeliveryDateString}`}`;
}
