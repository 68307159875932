import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import {
  IPurchaseStateCSV,
  IPurchaseStateWithCustomerRelationship,
} from "@smartrr/shared/entities/PurchaseState";
import {
  IPurchaseStateLineItemCSV,
  IPurchaseStateLineItemWithAsymmetricMetadata,
} from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { delay } from "@smartrr/shared/utils/delay";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";
import { getUpcomingPricingCycle } from "@smartrr/shared/utils/getDiscounts";

import { getSubscriptionTypeLabel } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";

import { dateParser } from "./dateFormatters";

export const mapPurchaseStatesWithDelay = async (
  subscriptionItems: IPurchaseStateWithCustomerRelationship[],
  activeOrg: IOrganization | null,
  currencyCode?: CurrencyCode,
  waitInterval = 500,
  chunkSize = 250
): Promise<IPurchaseStateCSV[]> => {
  const rows: IPurchaseStateCSV[] = [];
  for (let index = 0; index < subscriptionItems.length; index++) {
    //function delays every 'chunkSize' elements to free main thread for a couple of seconds
    //that way when user presses export it won`t freeze the app
    if (index % chunkSize === 0) {
      await delay(waitInterval);
    }
    const cps = subscriptionItems[index];
    const skdIdx = getUpcomingPricingCycle({ ...cps, organization: activeOrg! });

    rows.push({
      "Subscription ID": cps.shopifyId,
      "Upcoming Order": dateParser(cps.unpauseDate ?? cps.nextOrderDate, activeOrg?.billingTimezone),
      Customer: `${cps.custRel?.firstName} ${cps.custRel?.lastName}`,
      "Customer Email": cps.custRel?.email,
      "Est. Total": currencyCode
        ? formatMoney(cps.totalEstimatedNet, currencyCode)
        : `${+cps.totalEstimatedNet / 100}`,
      Items: `${cps.stLineItems
        .filter(item => (item.isAddOn || item.isRedeemed ? item.skdIdx === skdIdx : true))
        .reduce((acc, prev) => (acc += prev.quantity), 0)}`,
      Status: cps.purchaseStateStatus,
      "Created Date": dateParser(cps.createdDate, activeOrg?.billingTimezone),
    });
  }
  return rows;
};

export const mapPurchaseStateLineItemsWithDelay = async (
  subscriptionItems: IPurchaseStateLineItemWithAsymmetricMetadata[],
  activeOrg: IOrganization,
  currencyCode?: CurrencyCode,
  waitInterval = 500,
  chunkSize = 250
): Promise<IPurchaseStateLineItemCSV[]> => {
  const rows: IPurchaseStateLineItemCSV[] = [];
  for (let index = 0; index < subscriptionItems.length; index++) {
    //function delays every 'chunkSize' elements to free main thread for a couple of seconds
    //that way when user presses export it won`t freeze the app

    if (index % chunkSize === 0) {
      await delay(waitInterval);
    }

    const cpsli = subscriptionItems[index];

    if (!cpsli?.cps) {
      throw new Error(`Failed to join CPSs to CPSLIs for CPSLI id ${cpsli.id}`);
    }

    rows.push({
      lineItemShopifyId: cpsli.shopifyId,
      customerEmail: cpsli.cps?.custRel?.email,

      lineItemCreatedDate: dateParser(cpsli.createdDate, activeOrg.billingTimezone),
      lineItemLastUpdatedDate: dateParser(cpsli.updatedDate, activeOrg.billingTimezone),

      "variantShopifyId (parentheses if draft or archived)": cpsli.vnt?.shopifyId
        ? cpsli.vnt?.isDraftOrArchived
          ? `(${cpsli.vnt?.shopifyId})`
          : cpsli.vnt?.shopifyId
        : "",

      "purchasableAndVariantNames (parentheses if draft or archived)": cpsli.vnt?.isDraftOrArchived
        ? `(${cpsli.vnt?.purchasable?.purchasableName} - ${cpsli.vnt?.purchasableVariantName})`
        : `${cpsli.vnt?.purchasable?.purchasableName} - ${cpsli.vnt?.purchasableVariantName}`,

      lineItemBasePrice: currencyCode ? formatMoney(cpsli.basePrice, currencyCode) : `${cpsli.basePrice / 100}`,

      lineItemQuantity: cpsli.quantity,

      subscriptionShopifyId: cpsli.cps?.shopifyId,
      subscriptionStatus: getSubscriptionTypeLabel(activeOrg, cpsli.cps),
      subscriptionNextBillingDate: dateParser(cpsli.cps?.nextBillingDate, activeOrg.billingTimezone),
      subscriptionCancelledDate: dateParser(cpsli.cps?.cancelledAt, activeOrg.billingTimezone),
      "externalSubscriptionType (migrated from)": cpsli.cps?.externalSubscriptionType,
    });
  }
  return rows;
};
